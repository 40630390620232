import { GroupWidget } from '../components/Group/GroupWidget';
import { withLeanStyles } from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withLeanStyles';
import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {
  Environment,
  createBoundaryConfig,
} from '@wix/social-groups-common/dist/src/loggers/sentryConfig';

export default {
  component: withSentryErrorBoundary(
    withLeanStyles(GroupWidget, {
      cssPath: ['viewerWidget.stylable.bundle.css', 'viewerWidget.min.css'],
      enableMemoization: true,
    }),
    createBoundaryConfig(Environment.VIEWER),
  ),
};
